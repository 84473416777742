/**
 * See example of mixins here
 *  https://class-component.vuejs.org/guide/extend-and-mixins.html#extend
 *
 * needs :-
 * 1.   Import in component
 * import { Component, Vue , Mixins} from "vue-property-decorator";
 *
 * 2. Import class into component
 * import { Hello, World } from "../../../mixins/mixins";
 *
 * 3. extend Mixins not Vue. with the classes you need
 *
 * export default class AppSettingsLicenseLocations extends Mixins(Hello, World)  {
 *
 * 4. Access the properties
 * 	 console.log(this.hello + ' ' + this.world + '!')
 *
 * see test classes below
 *
 * NOTE - all mixin properties should be prefixed with mx, so that you can tell them from other class propertie !
 */

import { IUserDataLicense, IModule, IUserData, IUserTeam, PermissionCode } from "@/core/interfaces/UserDataInterface";

import { AxiosError } from "axios";
import Component from "vue-class-component";
import { ILayoutItem } from "@/core/interfaces/CustomFieldInterface";
import { Modules } from "../core/enums/LookupEnums";
import Vue from "vue";
import { formatError } from "../core/helpers/ErrorHelper";
import { formatCurrency } from "@/core/helpers/FieldHelper";

@Component
export class Settings extends Vue {
    get mxAppName(): string {
        return this.$store.getters["auth/settingsAppName"];
    }
    get mxDateFormat(): string {
        return this.$store.getters["auth/settingsDateFormat"];
    }
    get mxDateFormatNoYear(): string {
        let d = this.$store.getters["auth/settingsDateFormat"];
        d = d.replace(/y/gi, "").trim();
        //d = d.replace("/y/g", "").trim();
        return d;
    }
    get mxTimeFormat(): string {
        return this.$store.getters["auth/settingsTimeFormat"];
    }
    get mxMidnightFormat(): boolean {
        return this.$store.getters["auth/settingsMidnightFormat"];
    }
    get mxDateTimeFormat(): string {
        const d = this.$store.getters["auth/settingsDateFormat"];
        const t = this.$store.getters["auth/settingsTimeFormat"];
        return d + " " + t;
    }

    get mxDateTimeFormatNoYear(): string {
        let d = this.$store.getters["auth/settingsDateFormat"];
        const t = this.$store.getters["auth/settingsTimeFormat"];
        d = d.replace(/y/gi, "").trim();
        //d = d.replace("y", "").trim();
        return d + " " + t;
    }

    get mxTheme(): string {
        return this.$store.getters["auth/settingsColourScheme"];
    }
    get mxSessionTime(): string {
        return this.$store.getters["auth/settingsSessionTime"];
    }
    get mxBrandImage(): string {
        return this.$store.getters["auth/settingsBrandImage"];
    }
    get mxCurrentLicense(): IUserDataLicense | undefined {
        return this.$store.getters["auth/currentLicense"];
    }

    slug(title: string) {
        var slug = "";
        var titleLower = title.toLowerCase();
        slug = titleLower.replace(/đ/gi, 'd');
        slug = slug.replace(/\s*$/g, '');
        slug = slug.replace("*", '');
        slug = slug.trim();
        slug = slug.replace(/\s+/g, '_');
        return slug;
    }
}

@Component
export class Errors extends Vue {
    /**
     * Formats the error object into a message suitable for the AppErrorDisplay component
     *
     * @param error: AxiosError or Error
     * @param message: string - the default message if axios message is invalid
     * @returns string[] | string
     */
    mxFormatError(error: AxiosError | Error, message: string): string[] | string {
        return formatError(error, message);
    }
}

@Component
export class Permissions extends Vue {
    get mxUserData(): IUserData | null {
        return this.$store.getters["auth/currentUserData"];
    }

    get mxLicenseId(): number | null {
        if (this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.current_license_name) {
            return this.mxUserData.extra.current_license_id;
        } else return null;
    }

    get mxLicenseName(): string {
        if (this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.current_license_name) {
            return this.mxUserData.extra.current_license_name;
        } else return "";
    }

    get mxModules(): IModule[] {
        if (this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.modules) {
            return this.mxUserData.extra.modules;
        } else return [];
    }

    get mxPermissions(): PermissionCode[] {
        if (this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.permissions) {
            return this.mxUserData.extra.permissions;
        } else return [];
    }

    get mxIsSysAdmin(): boolean {
        return !!(this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.is_sys_admin);
    }

    get mxIsLicenseAdmin(): boolean {
        return !!(this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.is_license_admin);
    }
    get mxIsLicenseRootUser(): boolean {
        return !!(this.mxUserData && this.mxUserData.extra && this.mxUserData.extra.is_license_root_user);
    }

    mxUserId(): number | undefined {
        return this.mxUserData?.id;
    }

    mxIsInTeam(teamId: number): boolean {
        return !!(
            this.mxUserData &&
            this.mxUserData.extra &&
            this.mxUserData.extra.teams &&
            this.mxUserData.extra.teams.some((team: IUserTeam) => {
                return team.id === teamId;
            })
        );
    }

    mxHasModule(code: Modules): boolean {
        return this.mxModules.some((mod: IModule) => mod.id === code);
    }

    mxHasPermission(code: PermissionCode): boolean {
        return this.mxPermissions.includes(code);
    }

    mxHasSomePermissions(codes: PermissionCode[]): boolean {
        return this.mxPermissions.filter((x) => codes.includes(x)).length > 0;
    }

    mxHasAllPermissions(codes: PermissionCode[]): boolean {
        return codes.filter((x) => !this.mxPermissions.includes(x)).length > 0;
    }
}

@Component
export class Fields extends Permissions {
    mxVisibleMobile(field: ILayoutItem): boolean {
        return !!field.mobile_view;
    }

    mxVisibleAdvanced(field: ILayoutItem | null | undefined): boolean {
        if (field) {
			if(field.field_definition && field.field_definition.lku_master_table_id == "issues") {
				return !field.advanced_field || this.mxHasPermission("IS-IS-VADV");
			} else if(field.field_definition && field.field_definition.lku_master_table_id == "incidents") {
				return !field.advanced_field || this.mxHasPermission("IN-IN-VADV");
			} else {
				return true;
			}
		} else {
			return false;
		}
    }

    mxFindField(fields: ILayoutItem[] | null | undefined, name: string): ILayoutItem | null {
        if (fields) {
            for (let field of fields) {
                if (field.field_name === name) {
                    return field;
                }
                let childField = this.mxFindField(field.children, name);
                if (childField) {
                    return childField;
                }

            }
        }
        return null;
    }

    mxFormatCurrency(value: string | null): string | null {
        return formatCurrency(value);
    }
}

@Component
export class MapOptions extends Vue {
    /**
     * Returns a Promise<google.maps.MapOptions | null> to pass to the AppMapLoader when it resolves
     *
     * @param mapCentre: google.maps.LatLng | null - an initial value for the center of the map.
     */
    getMapConfig(mapCentre: google.maps.LatLng | google.maps.LatLngLiteral | null): Promise<google.maps.MapOptions | null> {
        return new Promise<google.maps.MapOptions | null>((resolve) => {
            let centre: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
            let zoom: number | null | undefined;
            const controlSize: number = 32;
            const styles: google.maps.MapTypeStyle[] = [{ featureType: "poi", stylers: [{ visibility: "off" }] }];
            if (mapCentre) {
                centre = mapCentre;
                zoom = 16;
                resolve({ controlSize: controlSize, zoom: zoom, center: centre, styles: styles });
            } else {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        centre = { lat: position.coords.latitude, lng: position.coords.longitude };
                        zoom = 15;
                        resolve({ controlSize: controlSize, zoom: zoom, center: centre, styles: styles });
                    },
                    () => {
                        centre =
                            Number(process.env.VUE_APP_GM_LAT) && Number(process.env.VUE_APP_GM_LNG)
                                ? { lat: Number(process.env.VUE_APP_GM_LAT), lng: Number(process.env.VUE_APP_GM_LNG) }
                                : { lat: -26.02203, lng: 134.26058 };
                        zoom = Number(process.env.VUE_APP_GM_ZOOM) ? Number(process.env.VUE_APP_GM_ZOOM) : 5;
                        resolve({ controlSize: controlSize, zoom: zoom, center: centre, styles: styles });
                    },
                    { timeout: 5000, }
                );
            }
        });
    }
}

@Component
export class Responsive extends Vue {
    $mq: any;
    mxBreakpoints: string[] = ["xxs", "xs", "sm", "md", "lg", "xl"];

    mxBreakpointIs(breakpoint: string) {
        return this.$mq == breakpoint;
    }

    mxBreakpointLessThan(breakpoint: string): boolean {
        const targetIndex = this.mxBreakpoints.indexOf(breakpoint);
        let debug = this.mxBreakpoints.slice(0, targetIndex);
        return this.mxBreakpoints.slice(0, targetIndex).indexOf(this.$mq) > -1;
    }

    mxBreakpointLessThanEqualTo(breakpoint: string): boolean {
        const targetIndex = this.mxBreakpoints.indexOf(breakpoint);
        return this.mxBreakpointIs(breakpoint) || this.mxBreakpoints.slice(0, targetIndex).indexOf(this.$mq) > -1;
    }

    mxBreakpointGreaterThan(breakpoint: string): boolean {
        const targetIndex = this.mxBreakpoints.indexOf(breakpoint);
        return this.mxBreakpoints.slice(targetIndex + 1).indexOf(this.$mq) > -1;
    }

    mxBreakpointGreaterThanEqualTo(breakpoint: string): boolean {
        const targetIndex = this.mxBreakpoints.indexOf(breakpoint);
        return this.mxBreakpointIs(breakpoint) || this.mxBreakpoints.slice(targetIndex + 1).indexOf(this.$mq) > -1;
    }
}

@Component
export class Routes extends Vue {
    baseUrl: string = "";

    /**
     * Gets the route to a given level between 1 and 5 relative to the base route.
     */
    getUrlAtLevel(level: number): string {
        const s1: string | undefined = this.$route.params.s1;
        const s2: string | undefined = this.$route.params.s2;
        const s3: string | undefined = this.$route.params.s3;
        const s4: string | undefined = this.$route.params.s4;
        const s5: string | undefined = this.$route.params.s5;
        let route = this.baseUrl;
        if (level >= 1 && s1) {
            route += "/" + s1;
            if (level >= 2 && s2) {
                route += "/" + s2;
                if (level >= 3 && s3) {
                    route += "/" + s3;
                    if (level >= 4 && s4) {
                        route += "/" + s4;
                        if (level >= 5 && s5) {
                            route += "/" + s5;
                        }
                    }
                }
            }
        }
        return route;
    }

    /**
     * Gets the slug at the a given level between 1 and 5.
     */
    getSlugAtLevel(level: number): string {
        let debug = `s${level}`;
        return this.$route.params[`s${level}`];
    }


    /**
     * Sets the route to the given level
     */
    goToUrlAtLevel(level: number): void {
        const newRoute = this.getUrlAtLevel(level);
        if (newRoute != this.$route.path) {
            this.$router.push(newRoute);
        }
    }

    /**
     * Return to the previous route
     */
    goBack(): void {
        this.$router.go(-1);
    }

    /**
     * Push route to the route stack
     *
     * @param route require route
     */
    goToUrl(route: string) {
        if (route != this.$route.path) {
            this.$router.push(route);
        }
    }
}

/**
 * Provides type safe access to compnent's $worker attribute
 */
@Component
export class Concurrent extends Vue {
    $worker: any;
}
